var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ProcessModelList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "tableLeftSlot",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.treeShow,
                        expression: "treeShow",
                      },
                    ],
                    staticClass: "table-left",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tree-search" },
                      [
                        _c("v-input", {
                          attrs: {
                            "prefix-icon": "el-icon-search",
                            placeholder: "请输入名称",
                          },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { ref: "treeSelect", staticClass: "tree-select" },
                      [
                        _c(
                          "v-button",
                          {
                            staticClass: "select-button",
                            class: { select: _vm.showTreeTypeList },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.showTreeTypeList = !_vm.showTreeTypeList
                              },
                            },
                            model: {
                              value: _vm.treeType,
                              callback: function ($$v) {
                                _vm.treeType = $$v
                              },
                              expression: "treeType",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.treeTypeMap[_vm.treeType])
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.treeTypeFilterOps.length > 0,
                                  expression: "treeTypeFilterOps.length > 0",
                                },
                              ],
                              staticClass: "el-icon-d-caret",
                            }),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.showTreeTypeList &&
                                  _vm.treeTypeFilterOps.length > 0,
                                expression:
                                  "showTreeTypeList && treeTypeFilterOps.length > 0",
                              },
                            ],
                            staticClass: "tree-select__list",
                          },
                          _vm._l(_vm.treeTypeFilterOps, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.treeTypeClick(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.text))]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tree-group" },
                      [
                        _c("button-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.treeLoading,
                              expression: "treeLoading",
                            },
                          ],
                          key: _vm.keyTree,
                          ref: "buttonTree",
                          attrs: {
                            data: _vm.treeData,
                            defaultProps: _vm.treeProps,
                            btnOps: _vm.btnOps,
                            filterText: _vm.filterText,
                            filterNodeMethod: _vm.filterNodeMethod,
                            defaultExpandedKeys: _vm.defaultExpandedKeys,
                            currentNodeKey: _vm.currentNodeKey,
                            highlightCurrent: true,
                            "expand-on-click-node": false,
                          },
                          on: {
                            buttonClick: _vm.buttonNode,
                            nodeClick: _vm.nodeClick,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "treeSwith", on: { click: _vm.treeSwith } },
                  [
                    _vm.treeShow
                      ? _c("img", { attrs: { src: _vm.treeOffImgSrc } })
                      : _vm._e(),
                    !_vm.treeShow
                      ? _c("img", { attrs: { src: _vm.treeOnImgSrc } })
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "流程名称", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.processName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "processName", $$v)
                    },
                    expression: "searchParams.processName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "流程标识", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.processKey,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "processKey", $$v)
                    },
                    expression: "searchParams.processKey",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "流程状态", options: _vm.processStatusOps },
                  model: {
                    value: _vm.searchParams.processStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "processStatus", $$v)
                    },
                    expression: "searchParams.processStatus",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.delFlag == 0
                  ? _c("v-button", {
                      attrs: {
                        text: "激活",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.editStatus(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.delFlag == 1
                  ? _c("v-button", {
                      attrs: {
                        text: "禁用",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.editStatus(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: {
                    text: "节点设置",
                    type: "text",
                    permission: "update",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getNodeData(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.viewTitle, width: "80%", visible: _vm.viewImage },
          on: {
            "update:visible": function ($event) {
              _vm.viewImage = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "min-height": "400px" } }, [
            _c("img", { attrs: { src: _vm.diagramUrl, alt: _vm.viewTitle } }),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑流程节点",
            visible: _vm.showProcessNodeEdit,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProcessNodeEdit = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-steps",
                    {
                      attrs: {
                        direction: "vertical",
                        active: _vm.current,
                        space: "100px",
                      },
                    },
                    _vm._l(_vm.nodeList, function (item, i) {
                      return _c("el-step", {
                        key: i,
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          title: item.title,
                          description: item.description,
                          status: i == _vm.current ? "process" : "wait",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.change_steps(item, i)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c("el-alert", {
                    attrs: {
                      title:
                        "温馨提示：若流程运行至未分配审批人员的审批节点时，流程将自动中断取消！",
                      type: "warning",
                      "show-icon": "",
                      closable: false,
                      effect: "dark",
                    },
                  }),
                  _c("span"),
                  _c(
                    "el-form",
                    { staticClass: "audit-from-wrapper" },
                    [
                      _c("el-form-item", { attrs: { label: "节点名称" } }, [
                        _c("span", { staticClass: "nodespan" }, [
                          _vm._v(_vm._s(_vm.editNode.title)),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "节点类型" } }, [
                        _c("span", { staticClass: "nodespan" }, [
                          _vm._v(_vm._s(_vm.dictNodeType[_vm.editNode.type])),
                        ]),
                      ]),
                      _c("el-alert", {
                        attrs: {
                          title:
                            "每个节点设置，如有修改都请保存一次，跳转节点后数据不会自动保存！",
                          type: "info",
                          "show-icon": "",
                          closable: false,
                          effect: "dark",
                        },
                      }),
                      _c("br"),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editNode.type == 1,
                              expression: "editNode.type==1",
                            },
                          ],
                          attrs: { label: "审批人员" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.spryType },
                              model: {
                                value: _vm.spryTypes,
                                callback: function ($$v) {
                                  _vm.spryTypes = $$v
                                },
                                expression: "spryTypes",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v(" 根据角色选择 "),
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v(" 直接选择人员 "),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v(" 根据组织角色选择 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.spryTypes == "0"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "选择角色" } },
                                [
                                  _c("chosen-list-panel", {
                                    attrs: {
                                      textAs: "name",
                                      list: _vm.sprys.roleIds,
                                    },
                                    on: {
                                      "update:list": function ($event) {
                                        return _vm.$set(
                                          _vm.sprys,
                                          "roleIds",
                                          $event
                                        )
                                      },
                                      select: _vm.toSelectRoleIds,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.spryTypes == "1"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "选择人员" } },
                                [
                                  _c("chosen-list-panel", {
                                    attrs: {
                                      textAs: "name",
                                      list: _vm.sprys.userIds,
                                    },
                                    on: {
                                      "update:list": function ($event) {
                                        return _vm.$set(
                                          _vm.sprys,
                                          "userIds",
                                          $event
                                        )
                                      },
                                      select: _vm.toSelectUserIds,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.spryTypes == "2"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orgId", label: "选择组织" } },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          placeholder: "选择组织",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.sprys.orgId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.sprys, "orgId", $$v)
                                          },
                                          expression: "sprys.orgId",
                                        },
                                      },
                                      "v-select2",
                                      _vm.orgParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orgRoleId",
                                    label: "选择组织角色",
                                  },
                                },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          subjoin: _vm.roleExtraParams,
                                          placeholder: "选择组织角色",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.sprys.orgRoleId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sprys,
                                              "orgRoleId",
                                              $$v
                                            )
                                          },
                                          expression: "sprys.orgRoleId",
                                        },
                                      },
                                      "v-select2",
                                      _vm.orgRoleParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                "html-type": "submit",
                                disabled:
                                  _vm.editNode.type == 0 ||
                                  _vm.editNode.type == 2 ||
                                  _vm.confirmLoading,
                              },
                              on: { click: _vm.sprySubmit },
                            },
                            [
                              _vm._v(
                                "\n                提交并保存\n              "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              on: { click: _vm.closeNode },
                            },
                            [_vm._v("\n                关闭\n              ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          isShow: _vm.isShowRoleSelect,
          searchUrl: _vm.roleSearchUrl,
          searchParams: _vm.roleSearchParams,
          responseParams: _vm.roleResponseParams,
          isMultiSelect: true,
          headers: _vm.roleHeaders,
          title: "角色选择",
          backFill: _vm.sprys.roleIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShowRoleSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.isShowRoleSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.sprys, "roleIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.sprys, "roleIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "角色名称" },
                  model: {
                    value: _vm.roleSearchParams.roleName,
                    callback: function ($$v) {
                      _vm.$set(_vm.roleSearchParams, "roleName", $$v)
                    },
                    expression: "roleSearchParams.roleName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "角色类型", options: _vm.roleTypeOps },
                  model: {
                    value: _vm.roleSearchParams.roleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.roleSearchParams, "roleType", $$v)
                    },
                    expression: "roleSearchParams.roleType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "角色归属" },
                  model: {
                    value: _vm.roleSearchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.roleSearchParams, "orgName", $$v)
                    },
                    expression: "roleSearchParams.orgName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("multi-select", {
        attrs: {
          isShow: _vm.isShowUserSelect,
          searchUrl: _vm.userSearchUrl,
          backFill: _vm.sprys.userIds,
          searchParams: _vm.userSearchParams,
          responseParams: _vm.userResponseParams,
          isMultiSelect: true,
          headers: _vm.userHeaders,
          title: "人员选择",
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShowUserSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.isShowUserSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.sprys, "userIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.sprys, "userIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "登录名" },
                  model: {
                    value: _vm.userSearchParams.logingName,
                    callback: function ($$v) {
                      _vm.$set(_vm.userSearchParams, "logingName", $$v)
                    },
                    expression: "userSearchParams.logingName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.userSearchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.userSearchParams, "userName", $$v)
                    },
                    expression: "userSearchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号" },
                  model: {
                    value: _vm.userSearchParams.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.userSearchParams, "phone", $$v)
                    },
                    expression: "userSearchParams.phone",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "角色类型", options: _vm.roleTypeOps },
                  model: {
                    value: _vm.userSearchParams.roleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.userSearchParams, "roleType", $$v)
                    },
                    expression: "userSearchParams.roleType",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }