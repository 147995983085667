import { mapHelper } from 'common/utils'

// 表单状态
const processStatus = [
  {
    text: '未激活',
    value: 0
  },
  {
    text: '已激活',
    value: 1
  }
]

const {
  map: processStatusMap,
  setOps: processStatusOps
} = mapHelper.setMap(processStatus)

// 按钮列表
const btnOps = [
  {
    text: '新增流程',
    value: 'addMemberBtn'
  }
]
// 是否启用
const delFlagOps = [
  {
    text: '不启用',
    value: 0
  },
  {
    text: '启用',
    value: 1
  }
]

// 树类型列表
const treeType = [
  {
    text: '平台职能部门',
    permission: 'platformBtn',
    value: 1
  },
  {
    text: '物业管理公司',
    permission: 'companyBtn',
    value: 2
  },
  {
    text: '社区治理单位',
    permission: 'communityBtn',
    value: 3
  }
]
const {
  map: treeTypeMap,
  setOps: treeTypeOps
} = mapHelper.setMap(treeType)

export {
  processStatusMap,
  processStatusOps,
  treeTypeMap,
  treeTypeOps,
  btnOps,
  delFlagOps
}