const baseURL = 'http://172.16.50.22:7020/manage'
// 获取列表接口
const getProcessModelListURL = `${API_CONFIG.butlerBaseURL}act/process/getProcessListByPage`
// 删除数据接口
const deleteURL = `${API_CONFIG.butlerBaseURL}activiti_process/delByIds`
// 启用流程
const updateStatusURL = `${API_CONFIG.butlerBaseURL}act/process/updateStatus`
// 转为模型
const convertToModelURL = `${API_CONFIG.butlerBaseURL}activiti_process/convertToModel`
// 获取流程节点
const getProcessNodeURL = `${API_CONFIG.butlerBaseURL}act/process/getProcessNode`
// 编辑流程节点
const editNodeUserURL = `${API_CONFIG.butlerBaseURL}act/process/editNodeUser`
// 用户选择
const userSearchURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`
// 角色选择
const roleSearchURL = `${API_CONFIG.controlBaseURL}sysRole/pageListSysRole`
// 组织角色选择
const getOrgInfoURL = `${API_CONFIG.butlerBaseURL}act/process/getOrgInfoSelect2`
const getRoleInfoURL = `${API_CONFIG.butlerBaseURL}act/process/getRoleInfoSelect2`
// 获取组织树
const getEnterOrgTreeListURL = `${API_CONFIG.butlerBaseURL}enterpriseOrgInfo/getEnterOrgTreeList`
// 获取模型列表
const getModelListURL = `${API_CONFIG.butlerBaseURL}actProcessIns/deployment/select2`
// 新增流程
const createURL = `${API_CONFIG.butlerBaseURL}act/process/addProcess`
// 获取单据类型列表
const getBilltypeListURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypeSelect2`
// 角色类型列表
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`

export {
    getProcessModelListURL,
    deleteURL,
    updateStatusURL,
    convertToModelURL,
    getProcessNodeURL,
    editNodeUserURL,
    userSearchURL,
    roleSearchURL,
    getOrgInfoURL,
    getRoleInfoURL,
    getEnterOrgTreeListURL,
    getModelListURL,
    createURL,
    getBilltypeListURL,
    getRoleTypeURL
}