<template>
  <div class="ProcessModelList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>

      </template>
      <!-- 左侧组织树 -->
      <template #tableLeftSlot>
        <div class="table-left" v-show="treeShow">
          <!-- 搜索框 -->
          <div class="tree-search">
            <v-input v-model="filterText" prefix-icon="el-icon-search" placeholder="请输入名称" />
          </div>
          <!-- 类型选择 -->
          <div class="tree-select" ref="treeSelect">
            <v-button v-model="treeType" class="select-button"
              :class="{select: showTreeTypeList}"
              @click.stop="showTreeTypeList = !showTreeTypeList"
            >
              {{treeTypeMap[treeType]}}<i v-show="treeTypeFilterOps.length > 0" class="el-icon-d-caret"></i>
            </v-button>
            <ul class="tree-select__list" v-show="showTreeTypeList && treeTypeFilterOps.length > 0">
              <li v-for="(item, index) in treeTypeFilterOps" :key="index" @click.stop="treeTypeClick(item)">{{item.text}}</li>
            </ul>
          </div>
          <!-- 控件树 -->
          <div class="tree-group">
            <button-tree
              ref="buttonTree"
              v-loading="treeLoading"
              :key="keyTree"
              :data="treeData"
              :defaultProps="treeProps"
              :btnOps="btnOps"
              :filterText="filterText"
              :filterNodeMethod="filterNodeMethod"
              :defaultExpandedKeys="defaultExpandedKeys"
              :currentNodeKey="currentNodeKey"
              :highlightCurrent="true"
              :expand-on-click-node="false"
              @buttonClick="buttonNode"
              @nodeClick="nodeClick"
            />
          </div>
        </div>
        <div class="treeSwith" @click="treeSwith">
          <img v-if="treeShow" :src="treeOffImgSrc" />
          <img v-if="!treeShow" :src="treeOnImgSrc" />
        </div>
      </template>
      <template #searchSlot>
        <v-input label="流程名称" v-model="searchParams.processName" placeholder="请输入" />
        <v-input label="流程标识" v-model="searchParams.processKey" placeholder="请输入" />
        <v-select label="流程状态" v-model="searchParams.processStatus" :options="processStatusOps" />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.delFlag == 0" text="激活" type="text" permission="update" @click="editStatus(scope.row)" />
        <v-button v-if="scope.row.delFlag == 1" text="禁用" type="text" permission="update" @click="editStatus(scope.row)" />
        <v-button text="节点设置" type="text" permission="update" @click="getNodeData(scope.row)" />
        <!-- <v-button text="转为模型" type="text" permission="update" @click="convertToModel(scope.row)" /> -->
        <!-- <v-button text="删除" type="text" permission="delete" @click="handleDelete(scope.row)" /> -->
      </template>
    </list>
    <el-dialog :title="viewTitle" width="80%" :visible.sync="viewImage">
      <div style="min-height: 400px">
        <img :src="diagramUrl" :alt="viewTitle">
      </div>
    </el-dialog>
    <el-dialog title="编辑流程节点"
      :visible.sync="showProcessNodeEdit" width="80%" >
      <el-row>
        <el-col :span="4" >
          <el-steps direction="vertical" :active="current" space="100px">
              <el-step v-for="(item,i) in nodeList" :key="i"
                style="cursor: pointer;"
                :title="item.title"
                :description="item.description"
                :status="i==current?'process':'wait'"
                @click.native="change_steps(item,i)"/>
          </el-steps>
        </el-col>
        <el-col :span="20">
          <el-alert
              title="温馨提示：若流程运行至未分配审批人员的审批节点时，流程将自动中断取消！"
              type="warning"
              show-icon
              :closable="false"
              effect="dark">
          </el-alert>
          <span></span>
          <el-form class="audit-from-wrapper"  >
              <el-form-item label="节点名称">
                  <span class="nodespan">{{ editNode.title }}</span>
              </el-form-item>
              <el-form-item label="节点类型">
                  <span class="nodespan">{{ dictNodeType[editNode.type] }}</span>
              </el-form-item>
              <el-alert
                  title="每个节点设置，如有修改都请保存一次，跳转节点后数据不会自动保存！"
                  type="info"
                  show-icon
                  :closable="false"
                  effect="dark">
              </el-alert>
              <br/>
              <el-form-item label="审批人员" v-show="editNode.type==1">
                  <el-radio-group @change="spryType" v-model="spryTypes" >
                      <!-- 0角色 1用户 3发起人-->
                      <el-radio label="0"> 根据角色选择 </el-radio>
                      <el-radio label="1"> 直接选择人员 </el-radio>
                      <el-radio label="2"> 根据组织角色选择 </el-radio>
                      <!-- <el-checkbox label="3">
                          发起人
                          <el-tooltip placement="right-start" content="自动获取发起人(暂不支持)">
                              <i class="el-icon-info"></i>
                          </el-tooltip>
                      </el-checkbox> -->
                  </el-radio-group>
              </el-form-item>
              <div v-if="spryTypes=='0'">
                <el-form-item  label="选择角色" >
                  <chosen-list-panel textAs="name" :list.sync="sprys.roleIds" @select="toSelectRoleIds"></chosen-list-panel>
                </el-form-item>
              </div>
              <div v-if="spryTypes=='1'">
                <el-form-item  label="选择人员" >
                  <chosen-list-panel textAs="name" :list.sync="sprys.userIds" @select="toSelectUserIds"></chosen-list-panel>
                </el-form-item>
              </div>

              <div v-if="spryTypes=='2'">
                <el-form-item prop="orgId"  label="选择组织" >
                  <v-select2
                  v-model="sprys.orgId"
                  placeholder="选择组织"
                  v-bind="orgParams"
                  :width="width" />
                </el-form-item>
                <el-form-item prop="orgRoleId" label="选择组织角色">
                  <v-select2
                  v-model="sprys.orgRoleId"
                  :subjoin="roleExtraParams"
                  placeholder="选择组织角色"
                  v-bind="orgRoleParams"
                  :width="width" />

                </el-form-item>
              </div>


              <el-form-item >
                <el-button @click="sprySubmit" type="primary" html-type="submit" :disabled="editNode.type==0||editNode.type==2||confirmLoading">
                  提交并保存
                </el-button>

                <el-button @click="closeNode" style="margin-left: 10px">
                  关闭
                </el-button>
              </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
    <multi-select
      :isShow.sync="isShowRoleSelect"
      :searchUrl="roleSearchUrl"
      :searchParams="roleSearchParams"
      :responseParams="roleResponseParams"
      :isMultiSelect="true"
      :headers="roleHeaders"
      title="角色选择"
      :backFill.sync="sprys.roleIds"
    >
      <template #searchSlot>
        <v-input v-model="roleSearchParams.roleName" label="角色名称"></v-input>
        <v-select label="角色类型" v-model="roleSearchParams.roleType" :options="roleTypeOps"></v-select>
        <v-input v-model="roleSearchParams.orgName" label="角色归属"></v-input>
      </template>
    </multi-select>

    <multi-select
      :isShow.sync="isShowUserSelect"
      :searchUrl="userSearchUrl"
      :backFill.sync="sprys.userIds"
      :searchParams="userSearchParams"
      :responseParams="userResponseParams"
      :isMultiSelect="true"
      :headers="userHeaders"
      title="人员选择"
    >
      <template #searchSlot>
        <v-input v-model="userSearchParams.logingName" label="登录名"></v-input>
        <v-input v-model="userSearchParams.userName" label="姓名"></v-input>
        <v-input v-model="userSearchParams.phone" label="手机号"></v-input>
        <v-select label="角色类型" v-model="userSearchParams.roleType" :options="roleTypeOps"></v-select>
      </template>
    </multi-select>
  </div>
</template>

<script>
import {
  getProcessModelListURL,
  deleteURL,
  updateStatusURL,
  viewImageURL,
  convertToModelURL,
  getProcessNodeURL,
  editNodeUser,
  roleSearchURL,
  userSearchURL,
  editNodeUserURL,
  getEnterOrgTreeListURL,
  getRoleInfoURL,
  getOrgInfoURL,
  getRoleTypeURL
} from './api'
import { processStatusOps,
  processStatusMap,
  btnOps,
  treeTypeOps,
  treeTypeMap } from './map'
import { Alert, Checkbox, CheckboxGroup, CheckboxButton, RadioGroup, Radio } from 'element-ui'
import { MultiSelect, ChosenListPanel, ButtonTree, SelectTree } from '@/components/bussiness'
// import { ButtonTree } from 'components/bussiness/index'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'
import { generateMapByOpts } from 'common/utils'

export default {
  name: 'ProcessModelList',
  components: {
    [Alert.name]: Alert,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    elRadio: Radio,
    elRadioGroup: RadioGroup,
    MultiSelect,
    ChosenListPanel,
    'button-tree': ButtonTree,
    'select-tree': SelectTree
  },
  data () {
    let _this = this
    return {
      // tree
      type: 0, // typeTree 改变的方式 1 表示通过按钮改变
      type2: 0, // currentNodeKey 改变的方式 1 表示组件更新时改变
      keyTree: 0,
      treeLoading: false,
      currentData: null, // 按钮点击时保存的节点数据
      currentItem: null, // 按钮点击时保存的按钮数据
      treeSelect: null, // 元素节点 el
      event: { // 存储事件的回调方法,用于组件销毁时去掉定时器
        click: null
      },
      // 组织树
      treeOnImgSrc,
      treeOffImgSrc,
      treeShow: true,
      filterText: '',
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgLis', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      btnOps,
      treeOrgData: {}, // select-button值对应的数据
      currentNodeKey: '',
      defaultExpandedKeys: [],
      // 组织类型
      showTreeTypeList: false,
      treeType: undefined,
      treeTypeOps: treeTypeOps(),
      treeTypeFilterOps: [],
      treeTypeMap,

      processStatusOps: processStatusOps(1),
      viewTitle:'',
      viewImage: false,
      diagramUrl: '',
      searchUrl: getProcessModelListURL,
      maxDate: new Date(),
      iframUrl: '',
      confirmLoading : false,
      searchParams: {
        processName: '',
        processKey: '',
        processStatus: undefined,
        orgId: 0
      },
      updateObj: {
          visible: false,
          confirmLoading: false
      },
      headers: [
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'name',
          label: '流程名称'
        },
        {
          prop: 'processKey',
          label: '流程标识'
        },
        {
          prop: 'version',
          label: '版本',
          formatter: row => "v."+row.version
        },
        {
          prop: 'diagramName',
          label: '流程图片'
        },
        {
          prop: 'delFlag',
          label: '状态',
          formatter: row => processStatusMap[row.delFlag]
        },
        {
          prop: 'description',
          label: '备注说明'
        },
        {
          prop: 'createTime',
          label: '部署时间'
        }
      ],
      nodeList: [],
      updateRow: {},
      editNode: {},
      current: 0,
      showProcessNodeEdit: false,
      dictNodeType: {
        '0': '开始节点',
        '1': '审批节点',
        '2': '结束节点'
      },
      spryTypes: undefined,
      sprys: {
        // 选中的用户
        userIds: [],
        roleIds: [],
        orgRoleIds: '',
        orgId: ''
      },
      spry: {
        // 选中的用户
        ids:[]
      },
      // 多选框对应数据
      // 账号多选参数
      isShowUserSelect: false,
      userSearchUrl: userSearchURL,
      userSelectedData: [],
      userHeaders: [
        {
          prop: 'userName',
          label: '姓名',
          show: true
        },
        {
          prop: 'loginName',
          label: '登录名',
          show: true
        },
        {
          prop: 'phone',
          label: '手机号',
          show: true
        },
        {
          prop: 'roleType',
          label: '角色类型',
          formatter (row) {
            return _this.roleTypeMap[row.userType]
          },
          show: true
        },
        {
          prop: 'orgNameNew',
          label: '角色归属',
          formatter (row) {
            return row.orgName ? row.orgName : '智慧园区一体化平台'
          },
          show: true
        },
        {
          prop: 'roleName',
          label: '所属角色',
          show: true
        }
      ],
      userSearchParams: {
        logingName : '',
        userName: '',
        phone: '',
        roleType:undefined
      },
      userResponseParams: {
        id: 'id',
        name: ['loginName', 'userName']
      },
      userResponseKey: {
        id: 'id',
        name: 'logingName'
      },
      userResponseOtherKey: ['phone'],
      // 角色多选参数
      isShowRoleSelect: false,
      roleSearchUrl: roleSearchURL,
      roleSelectedData: [],
      roleTypeOps: [],
      roleTypeMap: {},
      roleHeaders: [
        {
          prop: 'roleName',
          label: '角色名称',
          show: true
        },
        {
          prop: 'roleType',
          label: '角色类型',
          formatter (row) {
            return _this.roleTypeMap[row.roleType]
          },
          show: true
        },
        {
          prop: 'orgName',
          label: '角色归属',
          show: true
        },
        {
          prop: 'createTime',
          label: '创建时间',
          show: true
        },
        {
          prop: 'userCnt',
          label: '关联用户',
          show: true
        }
      ],
      roleSearchParams: {
        roleName: '',
        roleType: undefined,
        orgName: ''
      },
      roleResponseParams: {
        id: 'id',
        name: ['roleName','roleType']
      },
      roleResponseKey: {
        id: 'id',
        name: 'roleName'
      },
      roleResponseOtherKey: ['orgName'],
      // 选择组织角色相关变量
      orgParams: {
        searchUrl: getOrgInfoURL,
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'orgName',
          value: 'id'
        }
      },
      orgRoleParams: {
        searchUrl: getRoleInfoURL,
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'roleName',
          value: 'id'
        }
      },
      width: 200
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    },
    userInfo () {
      return this.$store.state.userInfo
    },
    menuPermission () {
      return this.$store.state.menuStore.menuPermission
    },
    roleExtraParams () {
      return {
        orgId: this.sprys.orgId
      }
    }
  },
  watch: {
    treeType (val) {
      console.log(val, 'leixng1')
      // 通过点击按钮导致的treeType改变,才触发下列代码
      this.currentData = null
      this.currentItem = null
      this.filterText = ''
      let orgId = this.searchParams.orgId
      this.searchParams = {
        orgId,
        processName: '',
        processKey: '',
        processStatus: undefined
      }
      this.currentNodeKey = orgId
      this.treeData = []
      if (this.type === 1) {
        // if (this.currentNodeKey === '') {
        //   console.log('此处触发2')
        //   this.$refs.list.searchData()
        // }
        this.currentNodeKey = ''
        this.defaultExpandedKeys = []
        this.type = 0
        this.getTreeList()
      }
    },
    currentNodeKey (val, old) {
      console.log("123",val,"1234",old,this.type2)
      // 1. 获取当前选中的key
      this.$set(this.searchParams, 'orgId', val)
      // console.log('currentNodeKey', `${val}----${old}`)
      // 2. 弹框状态和该组件更新时导致 currentNodeKey 的改变不触发查询列表
      if (!this.dialogVisible && this.type2 !== 1) {
        // console.log('此处触发1', this.searchParams)
        this.$refs.list.searchData(this.searchParams)
      }
      this.type2 = 0
    }
  },
  created () {
    /** 用于平台、物业、社区三个选项的权限问题 */
    console.log(this.menuPermission)
    const permission = this.menuPermission.processManage
    this.treeTypeFilterOps = this.treeTypeOps.filter(v => Number(permission[v.permission]) === 1)
    if (this.treeTypeFilterOps.length) {
      this.treeType = this.treeTypeFilterOps[0].value
    }
    /** end */
  },
  mounted () {
    // 获取角色类型
    this.getRoleType()
    /** 定义事件, 组件销毁时清空 */
    this.treeSelect = this.$refs.treeSelect
    this.event.click = () => {
      // console.log('点击body')
      this.showTreeTypeList = false
    }
    document.body.addEventListener('click', this.event.click)
    /** end */
    const orgTree = sessionStorage.getItem('orgTree')
    if (orgTree) {
      this.type2 = 1 // 当组件更新导致的 currentNodeKey 变化,不触发列表的查询
      const { orgId, orgParentId, orgType } = JSON.parse(orgTree)
      this.defaultExpandedKeys = orgParentId ? [String(orgParentId)] : []
      this.currentNodeKey = String(orgId)
      this.searchParams.orgId = String(orgId) // 默认调取一次列表接口时，参数确保正确
      this.treeType = orgType
      sessionStorage.removeItem('orgTree')
    }
    this.getTreeList()
    // this.$setBreadcrumb('管理组织')
  },
  destroyed () {
    document.body.removeEventListener('click', this.event.click)
  },
  methods: {
    getRoleType () {
      this.$axios.get(getRoleTypeURL).then(res => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          this.roleTypeMap = generateMapByOpts(this.roleTypeOps)
          !this.searchParams.roleType && (this.searchParams.roleType = data[0].id)
        }
      })
    },

    create () {
      if (this.visible) {
        this.createObj.visible = true
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'LeaveForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    getNodeData (row) {
        let _this = this
        _this.updateRow = row
        let param = {
          id : row.id
        }
        this.$axios.get(`${getProcessNodeURL}`,{params : param}).then(res => {
            console.log(res)
            if (res.status === 100) {
                // 转换null为""
                _this.nodeList = res.data || []
                console.log('_this.nodeList', _this.nodeList)
                if (_this.nodeList.length > 0) {
                    _this.editNode = _this.nodeList[_this.current]
                    console.log(_this.current, _this.editNode)
                    _this.showProcessNodeEdit = true
                }
            }
        })
    },
    change_steps (node, index) {
        this.spryTypes = undefined
        console.log('onChange:', node)
        this.current = index
        this.editNode = node
        /* 0角色 1用户 2组织角色 */
        let hasSetting = false
        if(node.type == 1) {
          if(node.nodeType == 0) {
            this.sprys.roleIds = []
            for (const role of node.roles || []) {
              this.sprys.roleIds.push({
                id : role.id,
                name: role.roleName,
                obj: role
              })
              hasSetting = true
            }
          } else if(node.nodeType == 1) {
            this.sprys.userIds = []
            for (const user of node.users || []) {
              this.sprys.userIds.push({
                id : user.id,
                name: user.nickname,
                obj: user
              })
              hasSetting = true
            }
          } else if(node.nodeType == 2) {
            let orgRoleInfo = node.orgAndRoles
            this.sprys.orgId = orgRoleInfo.orgId
            this.sprys.orgRoleId = orgRoleInfo.id
            hasSetting = true
          }
          if(hasSetting) this.spryTypes = node.nodeType + ''
          console.log('类型为', this.spryTypes)
        }
    },
    spryType (types) {
      console.log(types)
      // 是否选中角色
        if (this.spryTypes != '0') {
          this.sprys.roleIds = []
        }
        // 是否选中用户
        if (this.spryTypes != '1') {
          this.sprys.userIds = []
        }
        // 是否选中组织角色
        if (this.spryTypes != '2') {
          this.spry.orgRoleId = ''
          this.sprys.orgRoleId = ''
        }

    },
    closeNode () {
      console.log('close node edit')
        this.showProcessNodeEdit = false,
        this.current = 0,
        this.spryTypes = undefined,
        this.spry = {}
        this.sprys = {}
    },
    sprySubmit () {
        var _this = this
        if (this.spryTypes === undefined) {
            _this.$message.error('必须选择审批人！')
            return
        }
        let orgId = this.searchParams.orgId
        this.spry.ids = []
        if (this.sprys.roleIds && this.sprys.roleIds.length > 0) {
          this.spry.ids = this.sprys.roleIds.map(value=>{
            return value.id
          })
        }
        if (this.sprys.userIds && this.sprys.userIds.length > 0) {
          this.spry.ids = this.sprys.userIds.map(value=>{
            return value.id
          })
        }
        if (this.sprys.orgRoleId) {
          let orgRoleId = this.sprys.orgRoleId
          this.spry.ids.push(orgRoleId)
          orgId = this.sprys.orgId
        }
        if(this.spry.ids.length == 0) {
          _this.$message.error('必须选择审批人！')
            return
        }
        if(this.sprys.orgRoleId) {
          this.spry.orgRoleId = this.sprys.orgRoleId
        }
        _this.confirmLoading = true
        // 节点ID
        this.spry.nodeId = this.editNode.id
        // 流程定义ID
        this.spry.processId = this.updateRow.id
        // 审批人员类型
        this.spry.type = Number(this.spryTypes)
        // 流程所属组织
        this.spry.orgId = Number(orgId)
        console.log('请求参数',this.spry)
        this.$axios.post(editNodeUserURL, this.spry).then(res => {
            if (res.status === 100) {
                _this.$message.success('操作成功')
                /* 保存成功后回显数据 */
                _this.getNodeData(_this.updateRow)
            } else {
                // _this.$message.error(res.msg)
            }
        }).finally(() => _this.confirmLoading = false)
    },

    showResource (row) {
      console.log(row)
        this.viewTitle = '流程图片预览(' + row.diagramName + ')'
        this.diagramUrl = `${viewImageURL}?id=${row.id}`
        this.viewImage = true
    },

    async convertToModel (row) {
        const result = await this.$confirm(`您确认要转化流程${row.name}为模型?`, {
            title: '提示'
        })
        if(result) {
            var that = this
            let params = {
                id: row.id
            }
            that.$axios.post(convertToModelURL, null, { params }).then((res) => {
                if (res.status === 100) {
                    setTimeout(function () {
                        that.$message.success('转化成功')
                    }, 300)
                } else {
                    that.$message.warning(res.msg)
                }
                that.$refs.list.searchData()
            })
        }
    },
    async handleDelete (row) {
        const result = await this.$confirm(`您确认要删除流程${row.name}?`, {
            title: '提示'
        })
        if(result) {
            var that = this
            let params = {
                ids: row.id
            }
            that.$axios.delete(deleteURL, { params }).then((res) => {
                if (res.status === 100) {
                    that.$message.success(res.data)
                }
                that.$refs.list.searchData()
            })
        }
    },
    async editStatus (row) {
      console.log(row)
        let operation = ''
        let status = row.delFlag
        if (row.delFlag == 0) {
            operation = '激活'
            status = 1
        } else {
            operation = '禁用'
            status = 0
        }
        const result = await this.$confirm(`您确认要${operation}流程${row.name}?`, {
            title: '提示'
        })
        if(result) {
            var that = this
            let params = {
                delFlag: status,
                id: row.id,
                updateUserId: this.userInfo.id
            }
            that.$axios.get(updateStatusURL,{params}).then((res) => {
                if (res.status === 100) {
                    that.$message.success(res.data)
                } 
                that.$refs.list.searchData()
            })
        }
    },
    toSelectUserIds () {
      this.isShowUserSelect = true
    },
    userSelectSuccess (val) {
      console.log(val)
      console.log(this.sprys.userIds)
    },
    userSelectClose (val) {
      console.log(val)
      this.isShowUserSelect = false
    },
    toSelectRoleIds () {
      this.isShowRoleSelect = true
    },
    roleSelectSuccess (val) {
      console.log(val)
      this.sprys.roleIds = val.map(res=>{
        return res.id
      })
    },
    roleSelectClose (val) {
      console.log(val)
      this.isShowRoleSelect = false
    },

    /** 树节点相关 */
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.treeType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    treeSwith () { // 树的显示隐藏
      this.treeShow = !this.treeShow
    },
    treeTypeClick (item) { // 选择树节点类型 - 平台职能部门、物业管理公司、社区治理单位
      this.showTreeTypeList = !this.showTreeTypeList
      // type 1 - 表示通过点击按钮跳转类型 0 表示别的方式导致treeType的修改
      this.type = 1
      this.treeType = item.value
      console.log(1)
    },
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    buttonNode (data, item) { // 点击树节点的弹框按钮
      // data 为节点对象的数据, item 为按钮的对象数据
      this.currentData = data
      this.currentItem = item
      if (item.title) {
        this.dialogText = item.title
        this.dialogValue = item.code
        this.dialogVisible = true
        /**  物业管理公司第一级为租户, 需特殊处理 */
        const onOff = this.treeType === 2 &&
          item.value === 'deteleBtn' &&
          data[this.treeProps.btn].length === 4
        if (onOff) {
          this.dialogText = '删除公司'
          this.dialogValue = 5
        }
      } else {
        // 添加成员 + 设置按钮
        this.toJumpRoute(data, item)
      }
      // 以下为按钮的特殊需求
      // if (item.value === 'editBtn') { // 当点击编辑按钮时
      //   this.form.orgName = this.currentData[this.treeProps.label]
      // }
    },
    toJumpRoute (data, item) { // 跳转路由
      if (item.value === 'addMemberBtn') { // 添加流程
        this.$router.push({
          name: 'processModelForm',
          query: {
            orgId: data[this.treeProps.id],
            orgParentId: data[this.treeProps.parentId],
            orgType: this.treeType
          }
        })
        // console.log(this.treeType, 'this.treeType')
      } else if (item.value === 'setBtn') { // 设置
        this.$router.push({
          name: 'tenantForm',
          query: {
            tenantId: data['tenantId'],
            orgId: data[this.treeProps.id],
            orgParentId: data[this.treeProps.parentId],
            orgType: this.treeType
          }
        })
      }
    },
    nodeClick (data, node, nodeVue) { // 点击树节点文字
      /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      const val = data && (data[this.treeProps.id] || undefined)
      this.defaultExpandedKeys = val ? [val] : []
      this.currentNodeKey = val
    },

  }
}
</script>
<style lang="scss" scoped>
.ProcessModelList-wrapper {
  .table-left {
    height: 100%;
    display: inline-block;
    position: relative;
    .tree-search {
      text-align: center;
      ::v-deep .v-control {
        width: 100%;
        .el-input {
          width: 100% !important;
          .el-input__inner {
            outline: none;
            padding: 2px 10px 2px 30px;
            height: 33px;
          }
          .el-input__icon {
            line-height: 33px;
          }
          .el-input__suffix {
            line-height: 33px;
          }
        }
      }
    }
    ::v-deep .el-loading-mask {
      z-index: 100!important;
    }
    .tree-select {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      display: flex;
      z-index: 200;
      .select-button {
        width: 100%;
        // height: 100%;
        flex: 1;
        ::v-deep .el-button {
          padding: 0 8px;
          text-align: left;
          color: #414142;
          background-color: #e3e5e9;
          border-color: #e8e9eb;
          i {
            float: right;
          }
        }
      }
      .select {
        ::v-deep .el-button {
          background: linear-gradient(90deg, #43a8ff 0%, #529fff 99%);
          color: #fff;
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
      .tree-select__list {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 200;
        > li {
          cursor: pointer;
          padding: 0px 12px;
          color: #909399;
          &:hover {
            color: #1B8CF2;
            background-color: #f5f7fa;
          }
        }
      }
    }
    .tree-group {
      margin-top: 40px;
      height: calc(100% - 73px);
      width: 240px;
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .dialog-tree__node {
    text-align: left;
    .p-text {
      margin-top: 0;
    }
    .p-mar-0 {
      margin: 0;
    }
    .p-pad-l {
      padding-left: 45px;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
